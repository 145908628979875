<template>
  <div class="appointmentList-view">
      <div>
        <!-- <el-input
            class="w200 mb10 mr10"
            placeholder="请输入员工姓名"
            v-model="serchParams.name"
            clearable
        /> -->
        <el-select v-model="queryParam.produceId" placeholder="请选择产品" style="margin-right: 10px;">
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>

        <el-select v-model="queryParam.dealerId" placeholder="请选择代理商" v-if="userType == 2">
          <el-option
            v-for="item in dealerOptions"
            :key="item.id"
            :label="item.dealerName"
            :value="item.id">
          </el-option>
        </el-select>

        <el-button type="primary" class="myBtn" @click="serchquery" style="margin-left: 30px;">搜索</el-button>
        <el-button type="primary" class="myBtn" @click="reset">重置</el-button>
      </div>
    <!-- <el-button @click="add" class="el-button ml10 el-button--primary el-button--small">新增</el-button> -->
    <commonTable
      :tableData="tableData"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :currentPage="queryParam.pageNum"
      :loading="loading"
      :total="total"
    >
      <template v-slot:table>
        <el-table-column prop="dealerName" align="center" label="代理商"></el-table-column>
        <el-table-column prop="produceName" align="center" label="产品名称"></el-table-column>
        <el-table-column prop="handle" align="center" label="标识"></el-table-column>
        <el-table-column prop="startTime" align="center" label="活动开始时间"></el-table-column>
        <el-table-column prop="endTime" align="center" label="活动结束时间"></el-table-column>
        <el-table-column prop="prize" align="center" label="奖品名称"></el-table-column>
        <el-table-column prop="createTime" align="center" label="中奖时间"></el-table-column>
        <el-table-column prop="address" align="center" label="扫码地址"></el-table-column>
        <el-table-column prop="nickeName" align="center" label="中奖用户">
          <template slot-scope="scope">
            <el-image
                v-if="scope.row.avatarUrl"
                style="width: 50px; height: 50px;border-radius: 50%"
                :src="scope.row.avatarUrl"
                :fit="fit"
                :preview-src-list="[scope.row.avatarUrl]"
            ></el-image>
            <p>{{scope.row.nickeName||'微信用户'}}</p>
          </template>
        </el-table-column>

        <!-- <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="edit(scope.row)">编辑</el-button>
            <el-button type="text" @click="delUser(scope.row)">删除</el-button>
          </template>
        </el-table-column> -->
      </template>
    </commonTable>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import commTable from "@/mixins/commTable";
import { fetchWinUserList, fetchProList, fetchDealerList} from "@/api/winning.js"
import utils from "@/utils";
import {mapGetters} from "vuex";

export default {
  name: "Winning",
  mixins: [commTable],
  components: {
    commonTable,
  },
  data() {
    return {
      queryParam:{
        pageNum:1,
        pageSize: 10,
        produceId: '',
        dealerId: '',
      },
      loading: false,
      total:0,
      tableData: [],
      options: [],
      dealerOptions: [],
      userType: '',
    };
  },
  mounted() {
    // 表格
    this.getList()
    // 获取产品列表，不分页
    this.getProList()
    this.getchDealerList()
    this.userType = JSON.parse(localStorage.getItem('info')).userType
  },
  methods: {
    serchquery() {
      this.getList()
    },
    reset() {
      this.queryParam.produceId = ''
      this.queryParam.dealerId = ''
      this.queryParam.pageNum = 1
      this.getList()
    },
    getProList() {
      let params = {
        companyId: JSON.parse(localStorage.getItem('info')).companyId
      }
      fetchProList(params).then(res => {
        this.options = res.data
      })
    },
    getchDealerList() {

      fetchDealerList().then(res => {
        debugger
        console.log('11111111')
        this.dealerOptions = res.data
      })
    },
    getList() {
      let params = {
        ...this.queryParam
      }
      fetchWinUserList(params).then(res => {
        this.tableData = res.data.list
        this.total = res.data.total
      })
    },
    handleSizeChange(num) {
      this.queryParam.size = num
      this.queryParam.pageNum = 1
      this.getList()
    },
    handleCurrentChange(num) {
      this.queryParam.pageNum = num
      this.getList()
    }
  },
};
</script>

<style lang="scss" scoped>

</style>
